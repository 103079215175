// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--6o5UJ";
export var content = "index-module--content--CvqYw";
export var contentHover = "index-module--contentHover--6pPKh";
export var descBg = "index-module--descBg--g4fGp";
export var descContent = "index-module--descContent--H7Y7v";
export var experienceDemo = "index-module--experienceDemo--AjIzR";
export var experienceKPI = "index-module--experienceKPI--FVjFA";
export var guidance = "index-module--guidance--jJehI";
export var guidanceLogo = "index-module--guidanceLogo--1bF1-";
export var hoverShow = "index-module--hoverShow--Ms-IV";
export var hoverUp = "index-module--hover-up--pUvdD";
export var onlineRetailer = "index-module--onlineRetailer--nCm+s";
export var position_left = "index-module--position_left--H-bD9";
export var viewData = "index-module--viewData--6dt-0";
export var watchVideo = "index-module--watchVideo--yFL-l";